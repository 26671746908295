import React, {
    forwardRef,
    ReactElement,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import {
    FormGroup,
    ControlLabel,
    FormControl,
    InputGroup,
    Sizes,
} from 'react-bootstrap';
import FormValidationDisplay, {
    Validation,
} from 'common/components/form/FormValidationDisplay';
import './FormSelect.overrides.module.scss';
import { DropDownSelect, DropDownSelectOption } from '@motili/ui';
import {
    SingleValueProps,
    OptionProps,
    SelectComponentsConfig,
    GroupBase,
    SelectInstance,
} from 'react-select';
import styles from './FormSelect.module.scss';

export type Value = {
    value:
        | DropDownSelectOption
        | DropDownSelectOption[]
        | { id: number; display: string }
        | { id: number; display: string }[]
        | { id: string; display: string }
        | { id: string; display: string }[];
};
interface InputGroupConditionalWrapperProps {
    inputGroupBefore?: ReactElement;
    inputGroupAfter?: ReactElement;
    children: any;
}

interface FormSelectProps {
    label?: string;
    labelStyle?: React.CSSProperties;
    placeholder?: string;
    bsSize?: Sizes;
    className?: string;
    required?: boolean;
    optional?: boolean;
    validation?: Validation;
    controlId?: string;
    value?: string | [] | Object | number;
    options: any;
    disabled?: boolean;
    onChange?: (value: any) => void;
    onBlur?: () => void;
    multiple?: boolean;
    clearable?: boolean;
    searchable?: boolean;
    autoBlur?: boolean;
    inputGroupBefore?: ReactElement;
    inputGroupAfter?: ReactElement;
    valueRenderer?: (option: any) => JSX.Element;
    optionRenderer?: (option: any) => JSX.Element;
    button?: ReactNode;
    virtualize?: boolean;
    name?: string;
    selectValue?: (value: Value) => void;
    style?: React.CSSProperties;
    closeOnSelect?: boolean;
    isLoading?: boolean;
    onInputChange?: (value: any) => void;
    onMenuScrollToBottom?: (value: any) => void;
    getOptionValue?: (option: any) => string;
    getOptionLabel?: (option: any) => string;
    filterOption?: (
        option: DropDownSelectOption,
        inputValue: string
    ) => boolean;
    componentOverrides?: Partial<
        SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
    >;
    defaultValue?: DropDownSelectOption | DropDownSelectOption[];
}

const InputGroupConditionalWrapper = (
    props: InputGroupConditionalWrapperProps
) => {
    if (props.inputGroupBefore || props.inputGroupAfter) {
        return (
            <div className={styles.dropdownButton}>
                <InputGroup style={{ width: '100%' }}>
                    {props.children}
                </InputGroup>
            </div>
        );
    }
    return <span>{props.children}</span>;
};

const selectStyle = (props: FormSelectProps) => {
    if (props.validation?.state === 'error') {
        return { ...props.style, borderColor: '#e63232' };
    }
    return props.style;
};

const findOptionById = (
    options: any[],
    id: any,
    isMultiple: boolean = false
) => {
    if (isMultiple) {
        const ids = id;
        return ids.map((id: any) => options.find(option => option.id === id));
    }
    return options.find(option => option.id === id);
};

const typeCheckForValue = (value: any): boolean => {
    if (typeof value !== 'object') {
        return true;
    }
    if (!Array.isArray(value)) {
        return !value.id && !value.display;
    }
    return !value[0] || typeof value[0] !== 'object' || !value[0].id;
};

const getOptionValueDefault = (option: any) =>
    option.userId || option.id || option.property;
const getOptionLabelDefault = (option: any) =>
    option.display ||
    option.description ||
    option.name ||
    option.fullName ||
    option.text ||
    option.rawAddress ||
    '';

const FormSelect = forwardRef<
    SelectInstance<unknown, boolean, GroupBase<unknown>>,
    FormSelectProps
>((props, ref) => {
    const [selectedValue, setSelectedValue] = useState(
        props.value && typeCheckForValue(props.value)
            ? findOptionById(props.options, props.value, props.multiple)
            : props.value
    );

    const components: Partial<
        SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
    > = {};

    if (props.valueRenderer) {
        components.SingleValue = (singleValueProps: SingleValueProps<any>) =>
            props.valueRenderer!(singleValueProps.data);
    }

    if (props.optionRenderer) {
        components.Option = (optionProps: OptionProps<any, boolean>) =>
            props.optionRenderer!(optionProps);
    }

    useEffect(() => {
        if (props.value === null || props.value === undefined) {
            setSelectedValue(null);
        } else {
            const _value = typeCheckForValue(props.value)
                ? findOptionById(props.options, props.value, props.multiple)
                : props.value;
            setSelectedValue(_value);
        }
    }, [props.value, props.options, props.multiple]);
    const getOptionValue = props.getOptionValue || getOptionValueDefault;
    const getOptionLabel = props.getOptionLabel || getOptionLabelDefault;
    return (
        <FormGroup
            className={props.className}
            controlId={props.controlId}
            bsSize={props.bsSize}
            validationState={props.validation?.state}
        >
            <div className={styles.labelContainer}>
                <div>
                    {props.label && (
                        <ControlLabel
                            style={props.labelStyle || { fontSize: 12 }}
                        >
                            {props.label}
                        </ControlLabel>
                    )}
                    {props.required && (
                        <span className={styles.requiredStar}>*</span>
                    )}
                </div>
                <div>{props.button}</div>
            </div>
            <InputGroupConditionalWrapper {...props}>
                {props.inputGroupBefore || null}
                <div
                    className='form-select-overrides'
                    data-test-id={`${props.controlId}-dropdown`}
                >
                    <DropDownSelect
                        {...props}
                        value={selectedValue}
                        label=''
                        className={
                            props.inputGroupBefore ? styles.select : undefined
                        }
                        isMulti={props.multiple}
                        isClearable={props.clearable}
                        isDisabled={props.disabled}
                        closeMenuOnSelect={props.closeOnSelect}
                        isSearchable={props.searchable}
                        blurInputOnSelect={props.autoBlur}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        onChange={(value: any) => {
                            setSelectedValue(value);
                            if (props.selectValue) {
                                props.selectValue(value);
                            }
                            if (props.onChange) {
                                props.onChange(value);
                            }
                        }}
                        ref={ref}
                        style={selectStyle(props)}
                        componentOverrides={{
                            ...props.componentOverrides,
                            ...components,
                        }}
                        onInputChange={props.onInputChange}
                        onMenuScrollToBottom={props.onMenuScrollToBottom}
                        isLoading={props.isLoading}
                        filterOption={props.filterOption}
                        data-test-id={`${
                            props.label || ''
                        }-form-select-dropdown`}
                    />
                </div>
                {props.inputGroupAfter || null}
            </InputGroupConditionalWrapper>
            {props.validation?.state && (
                <FormControl.Feedback>
                    <FormValidationDisplay
                        validation={props.validation}
                        style={{ ...props.style, marginRight: 40 }}
                        hideIcon
                    />
                </FormControl.Feedback>
            )}
            {props.optional && <span className='input-optional'>Optional</span>}
        </FormGroup>
    );
});

FormSelect.defaultProps = {
    label: '',
    controlId: 'form-select-input',
    validation: { state: null },
    multiple: false,
    clearable: false,
    searchable: true,
    autoBlur: true,
    options: [],
    virtualize: false,
};

export default FormSelect;
